.premium-print {
  .banner {
    margin-bottom: 50px;

    .banner-content {
      position: relative;

      h2 {
        @include bg-opacity($desertSand, 0.65);
        @include font-roboto-light;

        color: $darkGrey;
        left: -20px;
        padding: 7px 0 7px 60px;
        position: absolute;
        top: 40px;
        width: 75%;

        @media (max-width: $screen-xs-max) {
          top: 10px;
          left: 15px;
          width: auto;
          right: 15px;
        }
      }

      img {
        width: 100%;
      }
    }
  }

  .banner:not(:first-child) {
    margin-top: 50px;
  }
}
