.container {
  .link-container {
    margin-bottom: 60px;

    .row {
      padding: 60px 0;
      background: $cararraColor;
    }

    .content {
      height: 230px;
      position: relative;

      @media (max-width: $screen-sm-max) {
        height: auto;
      }
    }

    h3 {
      @include font-roboto-regular;

      font-size: 30px;
    }

    .description {
      @include font-roboto-regular;

      color: $darkGrey;
      margin-top: 20px;
      margin-right: 50px;

      ul {
        padding: 0 0 0 15px;
      }
    }

    a {
      @include font-roboto-regular;
      @include transition(transform .2s);

      color: $darkGrey;
      text-transform: uppercase;
      display: inline-block;
      background-color: $desertSand;
      bottom: 0;
      left: 15px;
      padding: 10px 40px;
      position: absolute;
      text-decoration: none;
      margin: 20px 0 30px 0;

      &:hover {
        @include transform(scale(1.05));
      }

      @media (max-width: $screen-sm-max) {
        position: static;
      }
    }

    img {
      width: 100%;

      @media (max-width: $screen-sm-max) {
        margin-top: 25px;
      }
    }

    @media (max-width: $screen-sm-max) {
      margin-bottom: 60px;
    }
  }
}
