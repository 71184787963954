.info-container {
  @include font-roboto-light;

  background-color: $cararraColor;
  margin-top: 80px;
  padding: 20px 0;

  .content {
    margin-right: 50px;
  }

  &.info-image-container {
    padding-bottom: 40px;
    margin-bottom: 280px !important;

    .content {
      margin-right: 100px;
    }
  }

  .content-container {
    position: relative;

    img {
      position: absolute;
      width: 450px;
      bottom: -250px;
      right: 80px;

      @media (max-width: $screen-md-max) {
        width: 380px;
        bottom: -200px;
        right: 60px;
      }

      @media (max-width: $screen-sm-max) {
        width: 300px;
        bottom: -210px;
        right: 60px;
      }
    }
  }

  p, ul {
    font-size: 16px;
  }

  h5 {
    @include font-roboto-regular;

    font-size: 20px;
    margin-bottom: 20px;
  }
}
