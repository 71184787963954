header .navbar.navbar-default {
  background: none;
  border: none;
  margin: 100px 0 0 0;

  a {
    text-transform: uppercase;
  }

  .collapse.navbar-collapse {
    margin: 0;
    padding: 0;
  }
}
