.print-services {
  .print-service {
    height: 300px;
    margin-bottom: 30px;

    a {
      img {
        @include transition(transform .2s);

        width: 100%;
      }

      &:hover {
        img {
          @include transform(scale(1.05));

          opacity: 0.8;
        }
      }
    }
  }
}
