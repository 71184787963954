.info-grid {
  margin-top: 70px;

  .grid-item {
    @include font-roboto-light;

    padding: 30px 0;
    text-align: center;
    height: 220px;

    img {
      display: block;
      height: 30px;
      text-align: center;
      margin: 0 auto 10px auto;
    }

    h6 {
      @include font-roboto-regular;

      border-bottom: 1px solid $justRightColor;
      display: inline-block;
      font-size: 14px;
      padding-bottom: 5px;
    }

    p {
      font-size: 14px;
      margin-top: 10px;
    }

    a {
      color: $mineShaft;
      text-decoration: none;
    }

    @media (max-width: $screen-xs-max) {
      margin-bottom: 25px;
    }
  }
}
