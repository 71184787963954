.premium-print .price-table .price-cell {
  padding: 5px 0;
  text-align: center;
  margin-bottom: 10px;

  p {
    @include font-roboto-light;

    margin: 5px 15px;
    font-size: 13px;
    padding: 5px 0;
    display: block;
    border: $pancho 1px solid;

    @media (max-width: $screen-md-max) {
      margin: 5px 35px;
    }
  }
}
