.premium-print {
  .info-container {
    margin-top: 0;
    margin-bottom: 60px;
  }

  .info-grid {
    margin-top: 0;
    margin-bottom: 40px;
  }
}
