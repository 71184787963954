@mixin box-shadow($value) {
  -webkit-box-shadow: $value;
  -moz-box-shadow: $value;
  box-shadow: $value;
}

@mixin box-shadow-block {
  @include box-shadow(0 3px 17px 3px rgba(0, 0, 0, 0.05));
}

@mixin transform($transforms) {
  -moz-transform: $transforms;
  -o-transform: $transforms;
  -ms-transform: $transforms;
  -webkit-transform: $transforms;
  transform: $transforms;
}

@mixin bg-opacity($color, $opacity) {
  background: $color;
  background: rgba($color, $opacity);
}

@mixin border-radius($radius) {
  border-radius: $radius;
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
}

@mixin remove-border-radius {
  @include border-radius(0);
  -webkit-appearance: none;
}
