.breadcrumb {
  background-color: $cararraColor;
  li {
    a {
      color: $grey;
    }

    &.active {
      a {
        color: $mineShaft;
      }
    }
  }

}
