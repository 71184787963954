header .banner {
  height: 130px;
  background: url($baseurl + '/assets/images/banner.jpg') no-repeat center center;
  background-size: cover;

  h1 {
    @include font-roboto-light;

    color: $darkGrey;
    margin-top: 50px;

    @media (max-width: $screen-xs-max) {
      font-size: 35px;
    }

    @media (max-width: $screen-xs-max) {
      font-size: 20px;
    }
  }
}
