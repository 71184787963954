.panel-group {
  margin-bottom: 50px;

  h3, h4 {
    @include font-roboto-light;
  }

  .panel {
    border: none;

    .panel-heading {
      @include remove-border-radius;

      background-color: $cararraColor;
      padding: 0 0 0 20px;


      a {
        display: block;
        padding: 10px 20px;

        @media (max-width: $screen-xs-max) {
          padding: 10px 0;
        }
      }
    }

    .panel-body {
      border: none !important;
      padding: 20px 200px 20px 40px;
      background-color: $cararraColor;

      table {
        th, td {
         border: 1px solid $carnabyTanColor;
         padding: 5px 10px;
        }
      }

      @media (max-width: $screen-xs-max) {
        padding: 20px;
      }
    }
  }
}
