form {
  margin-top: 30px;

  label {
    display: block;
    margin-bottom:3px;
  }

  label.error {
    font-size: 12px;
    color: #C30;
    margin-top: 3px;
  }

  button {
    background: none;
    border: none;
    font-weight: 200;
    font-size: 25px;
    padding: 10px 20px;
    display: block;
    width: 100%;
    text-transform: uppercase;
  }

  input, textarea, select {
    @include remove-border-radius;

    background: $cararraColor;
    display: block;
    width: 100%;
    padding: 15px 20px;
    line-height: 12px;
    border: none;
    margin: 0;
    -webkit-appearance: none;
    line-height: 16px;
  }

  textarea {
    resize: vertical;
    min-height: 150px;
    width: 100%;
  }

  select {
    cursor: pointer;
    background: $cararraColor url($baseurl + '/assets/images/icons/triangle_down.png') no-repeat center right;
    background-size: 10px 9px;
    background-position-x: 95%;
  }

  .input-group {
    margin-bottom: 20px;

    &.input-small {
      float: left;
      width: 50%;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      padding-right: 10px;

      &.last {
        padding-right: 0;
        padding-left: 10px;

        @media (max-width: $screen-xs-max) {
          padding: 0;
        }
      }

      @media (max-width: $screen-xs-max) {
        width: 100%;
        padding: 0;
      }
    }

    &.input-full {
      width: 100%;
    }
  }
}
