a.wetransfer-button {
  @include transition(transform .2s);

  display: block;
  text-align: center;
  margin: 30px 0 60px 0;

  img {
    width: 200px;
  }

  &:hover {
    @include transform(scale(1.05));
  }
}
