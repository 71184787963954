footer {
  border-top: 1px solid $justRightColor;
  color: $grey;
  font-size: 12px;
  margin: 80px 0;
  padding-top: 30px;

  a {
    color: $grey;
    text-decoration: none;

    &:hover {
      color: $grey;
    }
  }

  .share-icons {
    float: right;
    list-style: none;
    margin: 0;
    padding: 0;

    li a {
      &:hover {
        opacity: 0.5;
      }

      img {
        width: 30px;
        margin-bottom: 5px;
      }
    }
  }

  .disclaimer {
    margin-top: 15px;
    text-align: right;

    a {
      color: $grey;
    }
  }
}
